import React, { useEffect } from 'react'
import classes from "../style/Home.module.css"
// import backVideo from "../assets/comingsoon.mp4"
import commingSoon from "../assets/backvideo.mp4"
import HomeCard from '../Components/HomeCard'

function Home({ isCard, setIsCard }) {
    useEffect(() => {
        const video = document.getElementById("bgvid")

        if (video) {
            video.addEventListener("timeupdate", () => {
                // console.log("first", video.currentTime)
                if (video.currentTime > 2.5) {
                    setIsCard(true)
                }
            })
        }

    }, [])
    return (
        <div className={`${classes.homeBody}`}>
            <video autoPlay='autoplay' muted id="bgvid" playsInline >
                {/* <source src={backVideo} type="video/webm" /> */}
                <source src={commingSoon} type="video/mp4" />
            </video>
            <HomeCard isCard={isCard} />

            {isCard && <div data-aos-delay="3100" className={classes.Footer} >
                <p className={classes.FooterTitle}>© 2024 by Chelsea’s Lounge</p>
            </div>}
        </div>
    )
}

export default Home