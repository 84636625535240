import { SET_PRODUCT_PROPS } from "../Helper/helper";


const inialstate = {
  email: "",
  isEmailSent: false
};

export const pageBuilderReducer = (state = inialstate, { payload, type }) => {
  if (type === SET_PRODUCT_PROPS) {
    return {
      ...state, ...payload
    };
  }
  return state;
};
