import React, { useEffect, useState } from 'react'
import Home from './Screens/Home'
import Aos from 'aos';
import 'aos/dist/aos.css';
function App() {
  const [isCard, setIsCard] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      Aos.init({
        offset: 0,
        duration: 900,
        easing: 'ease-in-sine',
      });
    }, 5000);
  }, [])
  return (
    <div className='App'>

      <Home setIsCard={setIsCard} isCard={isCard} />
    </div>
  )
}

export default App