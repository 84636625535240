import React, { useState } from 'react'
import classes from "../style/HomeCard.module.css"
// import desktop from "../assets/desktop.svg"
// import mobile from "../assets/mobile.svg"
import desktop from "../assets/desktop.png"
import mobile from "../assets/mobile.png"
import gif from "../assets/thank.png"
import { AiFillInstagram } from "react-icons/ai"
import axios from 'axios'
import Swal from 'sweetalert2'
function HomeCard({ isCard }) {
    const [email, setEmail] = useState("")
    const [isSending, setIsSending] = useState(false)
    const [error, setError] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const collectEmail = () => {
        if (!email) {
            setError(true)
            return
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setIsValidEmail(true)
            return
        }
        setIsSending(true)
        axios({
            method: "post",
            url: "https://formspree.io/f/xvojlwoa",
            data: {
                Email: email
            }
        }).then(res => {
            Swal.fire(
                {
                    title: 'Thank you',
                    text: 'サインアップしていただきありがとうございます',
                    showConfirmButton: false,
                    timer: 2000,
                    imageUrl: gif,
                    imageAlt: 'Custom image',
                    imageHeight: "100px",
                    imageWidth: "80px"
                }
            ).then(_ => {
                setIsSending(false)
                setEmail("")
            })
        }).catch(err => {

        })
    }
    return (
        <div className={`${classes.HomeCard} ${isCard ? classes?.showCard : ""}`}  >
            <div className={classes.topCard}>
                <img className={classes.desktopLogo} src={desktop} alt="" data-aos="fade" data-aos-delay="700" />
                <img className={classes.mobileLogo} src={mobile} alt="" data-aos="fade" data-aos-delay="1100" />
                <p className={classes.cardTitle} data-aos="fade" data-aos-delay="1500" >We are launching this spring</p>
                <p className={classes.cardTitleMobile} data-aos="fade" data-aos-delay="1500" >We are launching <br /> this spring</p>
                <div className={classes.bottomCard}>
                    <p data-aos="fade" data-aos-delay="1900" className={classes.bottomCardPara}>Eメールにご登録いただくと、素敵なコレクションを <br />
                        いち早くご覧いただけるほか、特別オファーもお届けします。</p>
                    <p data-aos="fade" data-aos-delay="1900" className={classes.bottomCardParaMobile}>Eメールにご登録いただくと、素敵なコレクションを <br /> いち早くご覧いただけるほか、<br />特別オファーもお届けします。</p>

                    <div data-aos-delay="2300" data-aos="fade" className={classes.HomeInputContainer}>
                        <div className={classes.emailContain}>
                            <input value={email} onChange={(e) => {
                                setError(false)
                                setIsValidEmail(false)
                                setEmail(e.target.value)
                            }} style={{ border: error ? "1px solid red" : "1px solid black" }} placeholder='Email address' autoFocus type="email" name="" id="" />
                            {
                                isValidEmail && <p className={classes.invalidEmail}>Invalid email</p>
                            }
                        </div>

                        <button disabled={isSending} onClick={collectEmail}>{isSending ? "Wait..." : "Sign up"}</button>
                    </div>


                </div>
            </div>
            <div data-aos="fade" data-aos-delay="2700" className={classes.cardFooter}>
                <p className={classes.cardFooterTitle}>Follow us on</p>

                <div onClick={() => window.open("https://www.instagram.com/chelseaslounge/?igshid=MzRlODBiNWFlZA%3D%3D", "_blank")} className={classes.cardFooterSocial}>
                    <AiFillInstagram />
                    <p className={classes.cardFooterSocialTitle}>chelseaslounge</p>
                </div>
            </div>
        </div>
    )
}

export default HomeCard